<template>
  <span
    v-tooltip="{ content: tooltip }"
    class="px-2 py-1 inline-flex items-center text-xs font-medium rounded-full whitespace-nowrap"
    :class="{ 'pl-1': iconComponent }"
  >
    <AsyncComp v-if="iconComponent" class="shrink-0 w-4 h-4 mr-1"></AsyncComp>
    {{ label }}
    <button v-if="removable" class="shrink-0 ml-1" :class="btnClass" @click="emit('remove')">
      <XIcon class="w-3 h-3" />
    </button>
  </span>
</template>

<script setup>
import { XIcon } from "@/components/icons"
import { defineAsyncComponent } from "vue"
const props = defineProps({
  label: String,
  removable: Boolean,
  tooltip: String,
  btnClass: String,
  iconComponent: String,
})
const emit = defineEmits(["remove"])
const AsyncComp = defineAsyncComponent(() =>
  import(`../../../../components/icons/${props.iconComponent}.vue`),
)
</script>
